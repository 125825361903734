import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as callbackbkIA7U1NQOMeta } from "/vercel/path0/pages/auth/callback.vue?macro=true";
import { default as blog3R7VDb7RegMeta } from "/vercel/path0/pages/blog.vue?macro=true";
import { default as company_45consulting9n06Mal0HfMeta } from "/vercel/path0/pages/company-consulting.vue?macro=true";
import { default as consultationsOUoGEevc74Meta } from "/vercel/path0/pages/consultations.vue?macro=true";
import { default as content_45authoringX7Knqqu40uMeta } from "/vercel/path0/pages/content-authoring.vue?macro=true";
import { default as _99_45cypress_45tipsR3sHUQrxDYMeta } from "/vercel/path0/pages/course/99-cypress-tips.vue?macro=true";
import { default as payment_45confirmationIY7ZksjcEnMeta } from "/vercel/path0/pages/course/payment-confirmation.vue?macro=true";
import { default as coursesGHU0htcrbwMeta } from "/vercel/path0/pages/courses.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as individual_45tutoringAmNw606GxBMeta } from "/vercel/path0/pages/individual-tutoring.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as newslettereMtymMTLo0Meta } from "/vercel/path0/pages/newsletter.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as private_45workshopsCAlj7xmJumMeta } from "/vercel/path0/pages/private-workshops.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as setting_45up_45trelloapp8AQMnts12FMeta } from "/vercel/path0/pages/setting-up-trelloapp.vue?macro=true";
import { default as speaking_45policy5ZZUxXoPPBMeta } from "/vercel/path0/pages/speaking-policy.vue?macro=true";
import { default as subscribedSyHLaAa3eQMeta } from "/vercel/path0/pages/subscribed.vue?macro=true";
import { default as talks_45webinarsqHZnO23Pa2Meta } from "/vercel/path0/pages/talks-webinars.vue?macro=true";
import { default as talksqef9bJlDYxMeta } from "/vercel/path0/pages/talks.vue?macro=true";
import { default as cypress_45api_91_91id_93_93Y7WMk77oUkMeta } from "/vercel/path0/pages/workshop/cypress-api[[id]].vue?macro=true";
import { default as cypress_45core_91_91id_93_93an6xCee7MBMeta } from "/vercel/path0/pages/workshop/cypress-core[[id]].vue?macro=true";
import { default as cypress_45typescript_91_91id_93_93ofPfBW6YlyMeta } from "/vercel/path0/pages/workshop/cypress-typescript[[id]].vue?macro=true";
import { default as registrationqV8Yqv2WbdMeta } from "/vercel/path0/pages/workshop/registration.vue?macro=true";
import { default as workshopslNITtBvECVMeta } from "/vercel/path0/pages/workshops.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/vercel/path0/pages/auth/callback.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog.vue")
  },
  {
    name: "company-consulting",
    path: "/company-consulting",
    component: () => import("/vercel/path0/pages/company-consulting.vue")
  },
  {
    name: "consultations",
    path: "/consultations",
    component: () => import("/vercel/path0/pages/consultations.vue")
  },
  {
    name: "content-authoring",
    path: "/content-authoring",
    component: () => import("/vercel/path0/pages/content-authoring.vue")
  },
  {
    name: "course-99-cypress-tips",
    path: "/course/99-cypress-tips",
    component: () => import("/vercel/path0/pages/course/99-cypress-tips.vue")
  },
  {
    name: "course-payment-confirmation",
    path: "/course/payment-confirmation",
    component: () => import("/vercel/path0/pages/course/payment-confirmation.vue")
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/vercel/path0/pages/courses.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "individual-tutoring",
    path: "/individual-tutoring",
    component: () => import("/vercel/path0/pages/individual-tutoring.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/vercel/path0/pages/newsletter.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "private-workshops",
    path: "/private-workshops",
    component: () => import("/vercel/path0/pages/private-workshops.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/vercel/path0/pages/profile.vue")
  },
  {
    name: "setting-up-trelloapp",
    path: "/setting-up-trelloapp",
    component: () => import("/vercel/path0/pages/setting-up-trelloapp.vue")
  },
  {
    name: "speaking-policy",
    path: "/speaking-policy",
    component: () => import("/vercel/path0/pages/speaking-policy.vue")
  },
  {
    name: "subscribed",
    path: "/subscribed",
    component: () => import("/vercel/path0/pages/subscribed.vue")
  },
  {
    name: "talks-webinars",
    path: "/talks-webinars",
    component: () => import("/vercel/path0/pages/talks-webinars.vue")
  },
  {
    name: "talks",
    path: "/talks",
    component: () => import("/vercel/path0/pages/talks.vue")
  },
  {
    name: "workshop-cypress-apiid",
    path: "/workshop/cypress-api:id?",
    component: () => import("/vercel/path0/pages/workshop/cypress-api[[id]].vue")
  },
  {
    name: "workshop-cypress-coreid",
    path: "/workshop/cypress-core:id?",
    component: () => import("/vercel/path0/pages/workshop/cypress-core[[id]].vue")
  },
  {
    name: "workshop-cypress-typescriptid",
    path: "/workshop/cypress-typescript:id?",
    component: () => import("/vercel/path0/pages/workshop/cypress-typescript[[id]].vue")
  },
  {
    name: "workshop-registration",
    path: "/workshop/registration",
    component: () => import("/vercel/path0/pages/workshop/registration.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    component: () => import("/vercel/path0/pages/workshops.vue")
  }
]